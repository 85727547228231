import { Loader } from '@screencloud/screencloud-ui-components';
import React from 'react';
import { Link, NotFoundBoundary, useLoadingRoute } from 'react-navi';
import { SemanticToastContainer } from 'react-semantic-toasts';
import { Dropdown, Menu } from 'semantic-ui-react';
import { useSession } from '../../state/session/useSession';
import { getRegions } from '../../utils/getRegions';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export function Layout({ children }: React.PropsWithChildren<unknown>) {
  const loadingRoute = useLoadingRoute();
  const session = useSession();
  const regions = getRegions();

  return (
    <div>
      <Menu fixed="top" inverted>
        <Menu.Item as={Link} href="/" header>
          Management Console {regions.find((x) => x.isCurrent)?.region.toUpperCase()}
        </Menu.Item>
        {regions
          .filter((r) => !r.isCurrent)
          .map((r) =>
            <Menu.Item key={r.region} as={Link} href={r.url}>{r.region.toUpperCase()}</Menu.Item>
          )
        }
        <Menu.Item as={Link} href="/org">
          Organisations
        </Menu.Item>
        <Menu.Item as={Link} href="/member">
          Members
        </Menu.Item>
        <Menu.Item as={Link} href="/app">
          Apps
        </Menu.Item>
        <Menu.Item as={Dropdown} href="/notify" simple item text="Notify">
          <Dropdown.Menu>
            <Dropdown.Item as={Link} href="/notify/target-device">Target Device</Dropdown.Item>
            <Dropdown.Item as={Link} href="/notify/criteria">Criteria</Dropdown.Item>
            <Dropdown.Item as={Link} href="/notify/message">Message</Dropdown.Item>
          </Dropdown.Menu>
        </Menu.Item>
        <Menu.Item as={Link} href="/migration">
          Migration
        </Menu.Item>
        <Menu.Item as={Dropdown} simple item text="Maintenance">
          <Dropdown.Menu>
            <Dropdown.Item as={Link} href="/maintenance/feature-flags">Feature Flags</Dropdown.Item>
            <Dropdown.Item as={Link} href="/maintenance/screen">Manual Screen Refresh</Dropdown.Item>
            <Dropdown.Item as={Link} href="/maintenance/scheduler">Cron Tasks</Dropdown.Item>
            <Dropdown.Item as={Link} href="/maintenance/screens-connectivity">Screens Connectivity</Dropdown.Item>
            <Dropdown.Item as={Link} href="/maintenance/screenshot-request">Screenshot Requests</Dropdown.Item>
          </Dropdown.Menu>
        </Menu.Item>
        <Menu.Item as={Dropdown} simple item position="right" text={session.user?.claims.email || 'not logged in'}>
          <Dropdown.Menu>
            <Dropdown.Header icon='setting' content='Settings' />
            <Dropdown.Item as={Link} href="/settings/user">Users</Dropdown.Item>
          </Dropdown.Menu>
        </Menu.Item>
      </Menu>

      <div
        style={{ marginTop: '5rem', marginLeft: '2rem', marginRight: '2rem' }}
      >
        <NotFoundBoundary render={() => <div>not found</div>}>
          {!!loadingRoute ? (
            <Loader active={!!loadingRoute}>Loading route</Loader>
          ) : (
            <ErrorBoundary>
              {children}
            </ErrorBoundary>
          )}
        </NotFoundBoundary>
      </div>
      <div style={{ position: 'fixed', top: '50px', right: '10px' }}>
        <SemanticToastContainer />
      </div>

    </div>
  );
}
